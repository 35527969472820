import { useEffect, useState } from "react";

import { useReactiveVar, useQuery } from "@apollo/client";
import { Avatar, AvatarImage, AvatarFallback } from "@radix-ui/react-avatar";
import { COMPANIES_SMALL_LIST } from "@/lib/queries/companies";
import { driver } from "driver.js";
import {
  CheckIcon,
  PanelLeftOpen,
  PanelRightOpen,
  Building
} from "lucide-react";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useSession } from "@/lib/hooks";
import { sidebarCollapsedVar } from "@/lib/local-state";
import { userDataVar } from "@/lib/local-state";
import { getCookie, setCookie } from "@/lib/utils";

import "driver.js/dist/driver.css";
import CompanyLiveSelector from "../ui/CompanyLiveSelector";

const randomBg = () => {
  const colors = [
    "bg-blue-500",
    "bg-green-500",
    "bg-yellow-500",
    "bg-red-500",
    "bg-indigo-500",
    "bg-purple-500",
    "bg-pink-500",
    "bg-slate-500",
  ];

  return colors[Math.floor(Math.random() * colors.length)];
};

const SectionTitleBar = ({ title, headline }) => {
  const sidebarCollapsed = useReactiveVar(sidebarCollapsedVar);
  const userData = useReactiveVar(userDataVar);
  const { clearSession, updateSession } = useSession("mvSession");
  const [avatarBg, setAvatarBg] = useState("bg-slate-100");
  const [openAlert, setOpenAlert] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [companies, setCompanies] = useState([]);

  const { data } = useQuery(COMPANIES_SMALL_LIST, {
    fetchPolicy: "cache-and-network",
    variables: {
      first: 10,
    },
  });

  useEffect(() => {
    if (data) {
      setCompanies(data.paginatedCompanies.edges);
    }
  }, [data]);

  const logOutHandler = () => {
    clearSession();
    window.location.href = "/login";
  };

  const handleCompanyChange = async (companyId) => {
    if (companyId == userData.companyId) return;
    setSelectedCompanyId(companyId);
    setOpenAlert(true);
  };

  const confirmCompanyChange = () => {
    userDataVar({ ...userData, companyId: selectedCompanyId, brandId: null });
    setOpenAlert(false);
    window.location.reload();
  };

  const cancelCompanyChange = () => {
    setOpenAlert(false);
    setSelectedCompanyId(null);
  };

  useEffect(() => {
    setAvatarBg(randomBg());

    const doneTour = getCookie("tour_done");
    if (!doneTour) {
      setCookie("tour_done", true, 365);
    }

    if (doneTour) return;

    setCookie("tour_done", true, 365);
    const driverObj = driver({
      showProgress: false,
      overlayOpacity: 0.3,
      smoothScroll: true,
      popoverClass: "popover-tour",
      steps: [
        {
          element: ".company-live-selector",
          popover: {
            title: "Select a Company",
            description:
              "Start by selecting a Company from this dropdown, this will help you to access its data and insights.",
            side: "bottom",
            align: "center",
          },
        },
        {
          element: ".brand-live-selector",
          popover: {
            title: "Select a Brand",
            description:
              "Select a Brand from this dropdown to access its data and insights.",
            side: "bottom",
            align: "center",
          },
        },
        {
          element: ".user-avatar",
          popover: {
            title: "User Options",
            description:
              "Use this control to access you basic user options, like Sign Out the tools app.",
            side: "bottom",
            align: "end",
          },
        },
        {
          element: ".app-sidebar",
          popover: {
            title: "Navigate the App",
            description:
              "Use the sidebar to navigate the app and access the different sections.",
            side: "right",
            align: "center",
          },
        },
        {
          element: ".sidebar-collapser",
          popover: {
            title: "Collapse the Sidebar",
            description:
              "Use this control to collapse or expand the sidebar, this will give you more space to work.",
            side: "right",
            align: "center",
          },
        },
      ],
    });
    //@TODO: Create feature flag to enable/disable tour
    //driverObj.drive();
  }, []);

  const handleSidebarCollapse = () => {
    sidebarCollapsedVar(!sidebarCollapsed);
    userDataVar({ ...userData, sidebarCollapsed: !sidebarCollapsed });
    updateSession("sidebarCollapsed", !sidebarCollapsed);
  };

  return (
    <>
      <div className={`transition-all ease-in-out duration-300 mb-4`}>
        <div className="flex flex-row items-center section-title-bar pb-4 justify-between">
          <div className="flex flex-row items-center space-x-2">
            {sidebarCollapsed ? (
              <PanelLeftOpen
                size={24}
                className="text-blue-600 dark:text-zinc-600 transition duration-150 ease-in-out mr-3 sidebar-collapser cursor-pointer"
                onClick={handleSidebarCollapse}
              />
            ) : (
              <PanelRightOpen
                size={24}
                className="text-blue-600 dark:text-zinc-600 transition duration-150 ease-in-out mr-3 sidebar-collapser cursor-pointer"
                onClick={handleSidebarCollapse}
              />
            )}

            <div>
              {title && (
                <div className="block">
                  <h2 className="text-md font-bold first:mt-0 leading-none dark:text-zinc-300">
                    {title}
                  </h2>
                </div>
              )}

              {headline && (
                <div>
                  <p className="text-slate-500 dark:text-zinc-500 text-xs leading-none mt-0.5">
                    {headline}
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="flex items-center">
            <div className="mr-2 flex items-center space-x-1">
              <div className="company-live-selector flex items-stretch">
                {companies.length > 1 && (
                  <>
                    <span className="text-xs text-slate-500 bg-white pl-2 flex items-center border-[1px] border-r-0 rounded-l-md dark:bg-zinc-700 dark:text-blue-400 dark:border-zinc-800">
                      <Building size={20} strokeWidth={1.5} />
                    </span>
                    <CompanyLiveSelector
                      value={userData?.companyId}
                      onValueChange={(value) => handleCompanyChange(value)}
                      className="input min-w-[180px] !shadow-none !rounded-l-none !border-l-0 hover:bg-white"
                    />
                  </>
                )}
              </div>
            </div>

            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Avatar
                  className={`user-avatar w-[30px] h-[30px] cursor-pointer flex items-center justify-center rounded-full ${avatarBg}`}
                >
                  <AvatarImage
                    className="rounded-full"
                    src={userData?.avatar}
                    alt={userData?.firstName}
                  />
                  <AvatarFallback className="font-bold text-white">
                    {userData?.firstName?.[0]}
                    {userData?.lastName?.[0] || ""}
                  </AvatarFallback>
                </Avatar>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56" align="end">
                <DropdownMenuLabel>
                  <strong className="leading-none block">
                    {userData?.firstName} {userData?.lastName}
                  </strong>
                  <small className="font-normal text-blue-600">
                    {userData?.email}
                  </small>
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuGroup>
                  <DropdownMenuItem
                    className="justify-between"
                    onClick={() =>
                      userDataVar({
                        ...userData,
                        theme: userData.theme === "dark" ? "light" : "dark",
                      })
                    }
                  >
                    Dark Mode
                    {userData?.theme === "dark" && (
                      <DropdownMenuShortcut>
                        <CheckIcon className="text-blue-600" size={16} />
                      </DropdownMenuShortcut>
                    )}
                  </DropdownMenuItem>
                </DropdownMenuGroup>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  onClick={logOutHandler}
                  className="text-red-600"
                >
                  Log out
                  <DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>

      <AlertDialog open={openAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Confirm Company Change</AlertDialogTitle>
            <AlertDialogDescription>
              By selecting company, the application context will be loaded for
              company. Are you sure you want to continue?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={cancelCompanyChange}>
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={confirmCompanyChange}
              className="bg-blue-600 hover:bg-blue-700"
            >
              Confirm
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

const SectionTitleContent = ({ children }) => {
  return <div className="flex flex-col">{children}</div>;
};

const SectionTitleControls = ({ children }) => {
  return (
    <div className="flex-1 flex flex-row items-center justify-end space-x-2">
      {children}
    </div>
  );
};

export { SectionTitleBar, SectionTitleContent, SectionTitleControls };
