import { useState, useEffect, useCallback } from "react";

import { useLazyQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import debounce from "lodash.debounce";
import { Check, ChevronsUpDown, Search } from "lucide-react";

import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { COMPANIES_SMALL_LIST } from "@/lib/queries/companies";
import { cn } from "@/lib/utils";

import { Input } from "../ui/input";

const SELECTED_COMPANY_QUERY = gql`
  query Company($id: ID!) {
    party(id: $id) {
      id
      details {
        ... on CompanyDetails {
          name
        }
      }
    }
  }
`;

const CompanyLiveSelector = ({ value, onValueChange, className, allCaption, ...props }) => {
  const [filters, setFilters] = useState(null);
  const [open, setOpen] = useState(false);
  const [companySearch, setCompanySearch] = useState("");
  const [current, setCurrent] = useState(null);

  const [getCompanies, { data: companies, loading }] = useLazyQuery(COMPANIES_SMALL_LIST, {
    fetchPolicy: "cache-and-network",
    variables: {
      first: 10,
      after: null,
      filters: filters,
    },
  });
  const [fetchCompany, { data: companyData }] = useLazyQuery(SELECTED_COMPANY_QUERY);

  useEffect(() => {
    if (!value) return;
    const fetchSelectedCompany = async () => {
      await fetchCompany({
        variables: {
          id: value,
        },
      });
    };
    fetchSelectedCompany();
  }, [value]);

  useEffect(() => {
    if (companyData) {
      setCurrent(companyData.party.details);
    }
  }, [companyData]);

  useEffect(() => {
    if (open) {
      const fetchCompanies = async () => {
        await setFilters(null);
        try {
          await getCompanies();
        } catch (error) {
          console.error("error", error);
        }
      };
      fetchCompanies();
    }
  }, [open]);

  const updateFilters = (searchValue) => {
    if (searchValue) {
      setFilters({
        keyword: searchValue,
      });
      getCompanies();
    } else {
      setFilters(null);
    }
  };

  const debouncedSetCompanySearch = useCallback(
    debounce((newValue) => updateFilters(newValue), 300),
    [],
  );

  useEffect(() => {
    debouncedSetCompanySearch(companySearch);
  }, [companySearch]);

  const findCompanyDetails = (companyId) => {
    return companies?.paginatedCompanies?.edges?.find(({ node: company }) => company.id === companyId)?.node
      .details;
  };

  const selectValue = (companyId) => {
    onValueChange(companyId);
    setCurrent(findCompanyDetails(companyId));
    setOpen(false);
  };

  return (
    <Popover open={open} onOpenChange={setOpen} modal={true}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn(
            "w-full flex justify-between items-center dark:hover:bg-zinc-700 dark:bg-zinc-700 dark:text-zinc-400",
            className,
          )}
          {...props}
        >
          {value ? current?.name : "Select company..."}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>

      <PopoverContent className="PopoverContent p-0 dark:border-none">
        <div
          className={cn(
            "flex h-full w-full flex-col rounded-md bg-white text-slate-950 dark:bg-zinc-700 dark:text-zinc-300",
          )}
        >
          <div className="flex items-center border-b px-2 dark:border-b-zinc-600">
            <Search className="mr-1 h-4 w-4 shrink-0 opacity-50" />
            <Input
              className={cn(
                "!border-0 flex-1 flex !h-8 w-full rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-slate-500 disabled:cursor-not-allowed disabled:opacity-50 dark:placeholder:text-zinc-500 focus-visible:ring-0 focus-visible:shadow-none",
              )}
              placeholder="Search companies..."
              value={companySearch}
              onChange={(e) => setCompanySearch(e.target.value)}
            />
          </div>

          <div className="max-h-[90%] overflow-y-auto">
            {!loading && (
              <div
                className={cn(
                  "relative hover:bg-slate-100 dark:hover:bg-zinc-800 my-1 flex cursor-pointer select-none items-center rounded-sm px-2 py-1 text-sm outline-none aria-selected:bg-slate-100 aria-selected:text-slate-900 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 dark:aria-selected:bg-slate-800 dark:aria-selected:text-slate-50",
                )}
                value={null}
                onClick={() => selectValue(null)}
              >
                <div className="flex items-center justify-between w-full">
                  <div className="flex flex-col">
                    <span className="leading-tight">{allCaption || "All companies"}</span>
                    <small className="text-slate-500 dark:text-zinc-400 leading-tight">
                      No company selected
                    </small>
                  </div>
                  <Check className={cn("mr-2 h-4 w-4", value === null ? "opacity-100" : "opacity-0")} />
                </div>
              </div>
            )}
            {companies?.paginatedCompanies?.edges?.map(({ node: company }) => (
              <div
                className={cn(
                  "relative hover:bg-slate-100 dark:hover:bg-zinc-800 my-1 flex cursor-pointer select-none items-center rounded-sm px-2 py-1 text-sm outline-none aria-selected:bg-slate-100 aria-selected:text-slate-900 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 dark:aria-selected:bg-zinc-800 dark:aria-selected:text-zinc-400",
                )}
                key={company.id}
                value={company.id}
                onClick={() => selectValue(company.id)}
              >
                <div className="flex items-center justify-between w-full">
                  <div className="flex flex-col">
                    <span className="leading-tight">{company.details.name}</span>
                    <small className="text-slate-50 dark:text-zinc-400 leading-tight">
                      {company.details.companyType.toLowerCase()}
                    </small>
                  </div>
                  <Check className={cn("mr-2 h-4 w-4", value === company.id ? "opacity-100" : "opacity-0")} />
                </div>
              </div>
            ))}

            {loading && (
              <div
                className={cn(
                  "relative hover:bg-slate-100 my-1 flex cursor-pointer select-none items-center rounded-sm px-2 py-1 text-sm outline-none aria-selected:bg-slate-100 aria-selected:text-slate-900 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 dark:aria-selected:bg-slate-800 dark:aria-selected:text-slate-50",
                )}
              >
                Loading...
              </div>
            )}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default CompanyLiveSelector;
